const palette: Record<string, Record<number, string>> = {
  orange: {
    900: "#1b0704",
    700: "#40190c",
    400: "#7d3c1b",
    100: "#bb6529",
    90: "#DD7F32",
    60: "#e8ae7a",
    40: "#eec99f",
    30: "#f2d9b3",
    20: "#f5e4c4",
    10: "#faf4e6",
  },
  red: {
    900: "#1c0208",
    700: "#571628",
    400: "#943853",
    100: "#ca5a7c",
    90: "#E76E92",
    60: "#ee9db2",
    40: "#f4bcc7",
    30: "#f7d1d5",
    20: "#fae2e3",
    10: "#fdf7f7",
  },
  purple: {
    900: "#0e0c15",
    700: "#2f273e",
    400: "#5d4972",
    100: "#8868a1",
    90: "#9F78BA",
    60: "#c0a5d1",
    40: "#d6c3e0",
    30: "#e5d7ea",
    20: "#f0e6f2",
    10: "#f8f4f9",
  },
  blue: {
    900: "#0a0f15",
    700: "#1a2635",
    400: "#395175",
    100: "#5776b1",
    90: "#6788D1",
    60: "#9aade0",
    40: "#bfc8eb",
    30: "#d7dbf3",
    20: "#e8e9f8",
    10: "#f4f4fb",
  },
  green: {
    900: "#0d1202",
    700: "#1d280a",
    400: "#3e561e",
    100: "#5a8036",
    90: "#6A9847",
    60: "#8fba79",
    40: "#abd59d",
    30: "#c3e6bc",
    20: "#d8f1d4",
    10: "#eff9ed",
  },
  gold: {
    900: "#141100",
    700: "#2b2505",
    400: "#584e15",
    100: "#81752c",
    90: "#9F903F",
    60: "#bfb272",
    40: "#d6cb8e",
    30: "#e5da9f",
    20: "#f0e7b6",
    10: "#f9f5e0",
  },
  gray: {
    900: "#090b0a",
    700: "#252b28",
    400: "#48534f",
    100: "#6b7b76",
    90: "#7e918c",
    60: "#a9b5b3",
    40: "#c7cfcd",
    30: "#dadfde",
    20: "#e9eceb",
    10: "#fafafa",
  },
};

export { palette };
