const consoleWelcome = (): void => {
  console.log(
    `                                                            
                                           .....                
                                        .,ldxkkxo;.             
                                       ,c:,....,ckk;            
                                     .;c'        .x0;           
  .--------------.               .;:lxOx;.        cKo.          
 / Welcome to the \\          ..',,,cxdlc.        lKl.          
 \\   console!!    /           .     ,:.          'kXkl;.        
   .____________ \\               .....           ,dxc:loc.      
                  \\          .,oxO0kc..'.       ...   .,c'     
                             ..l0KNWWO,.:'       ..'.  ....     
                            ...'cdxxdc;c;.     .oO0K0o..'.      
                             ...',col;,.       'xXNWW0,.:;      
                                 .dNo.       ...,cool:;c:.      
                                 .xNl          ;kxc,;;;.        
                                 .kNl.cc.      cNK;   .'.       
                                 .kNc,00'      lNO.  ,OO'       
                                 .kXc,0k.      lNx. .kKc        
                                 .kK:,0x.      lXo  cKd.        
                                 .x0;,Ox.      lXl .d0:         
                                 .xO;,kd.      lXc 'xk,         
                                 .oO;'dx.      cKl 'xk,         
                                  lk;.cO;      :Oc .xO,         
                                  :Oc..dd'    .co' .dO;         
                                  'xo. .ldc;;:lc'  .xKc         
                                  .lx,   .,;;;'    .kXc         
                                   ,xc.            .OK;         
                                    ld'            'Ok.         
                                    .oo'           :x:          
                                     .ox:.       .,l;           
                                      .;ddl;,,,;::,.            
                                         .,;;,,'..              
                                                                
`
  );

  console.log(
    "%cAre you a fellow developer? You can check out the source: https://gitlab.com/soetz/soetz-dot-codes",
    "display: inline-block; margin: 20px; margin-bottom: 40px; font-family: boreal; font-size: 20px;"
  );
};

export default consoleWelcome;
